body[data-page="home"] {
  overflow-x: hidden;
}

body[data-page="home"] .App {
  --background: #003bb8;
  background-image: linear-gradient(120deg, #003bb8 0%, #269edb 100%);
}

body[data-page="home"] .footer {
  color: #fefefe;
  background: #1b1b1b;
}

body[data-page="home"] .grecaptcha-badge {
  opacity: 0;
  pointer-events: none;
}

.home {
  width: 100%;
  max-width: 100vw;
  /* height: calc(100vh - 80px); */
  /* margin-bottom: 180px; */
}

.intro {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
}

.intro *::selection {
  background: inherit;
}

.introCards *::selection {
  background: inherit;
}

.introCards {
  margin: 50px 80px;
  position: relative;
  width: 40%;
  max-height: 100vh;
  cursor: pointer;
}

.introCard {
  position: absolute;
  transform-origin: bottom right;
  transform: rotateZ(6deg);
  opacity: 0;
  z-index: 1;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 15px 13px;
  min-width: 560px;
  width: 90%;
  max-width: 650px;
  min-height: 360px;
  background: #2a2a32;
  border-radius: 10px;
  box-shadow: 4px 4px 20px 5px rgba(0, 0, 0, 0.3);
  transition: 0.5s all ease-in-out;
  pointer-events: none;
}

.followed {
  height: 360px;
  overflow: hidden;
}

.circles {
  display: flex;
  /* padding: 15px 0 15px 13px; */
}

.navCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fefefe;
  margin: 0 4px;
}

.red {
  background: #ed4040;
}

.yellow {
  background: #ecb62c;
}

.green {
  background: #26e05a;
}

.procode {
  margin-top: 20px;
  /* font-size: 1.5em; */
  font-size: 19.5px;
  line-height: 25px;
  color: #fefefe;
}

.CodeMirror-line {
  overflow: visible;
  white-space: pre-wrap;
  word-break: normal;
  margin: 0;
}

.front {
  opacity: 1;
  transform: rotateZ(0);
  z-index: 10;
  pointer-events: auto;
}

.followed {
  opacity: 0.5;
  transform-origin: bottom right;
  transform: rotateZ(5deg);
  box-shadow: none;
  z-index: 8;
}

.fall {
  transform-origin: bottom right;
  box-shadow: none;
  z-index: 12;
  animation: falldown 1s ease-in-out;
}

@keyframes falldown {
  0% {
    opacity: 1;
    transform: rotateZ(0);
  }

  70% {
    opacity: 0.5;
  }

  90% {
    opacity: 0;
    transform: rotateZ(-90deg) scale(0.9);
  }

  100% {
    opacity: 0;
    transform: rotateZ(-90deg) scale(0);
  }
}

.profileImage {
  position: relative;
  right: -60px;
  width: 900px;
  height: 840px;
  display: grid;
  place-items: baseline end;
}

.introImage {
  position: relative;
  z-index: 3;
  /* top: 0; */
  /* left: 0; */
  max-width: 100%;
  max-width: 500px;
  height: auto;
  border: 6px solid #f7e9e9;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 20px rgba(7, 8, 18, 0.4));
  transform-origin: center;
  animation: twiddle 5s linear infinite alternate;
}

@keyframes twiddle {
  from {
    transform: rotateZ(-2deg);
  }

  to {
    transform: rotateZ(2deg);
  }
}

.ringCircle {
  position: absolute;
  /* top: 0; */
  /* left: 0; */
  width: 500px;
  height: 500px;
  transform-origin: center;
  border: 2px solid rgba(19, 20, 33, 0.5);
  border-radius: 50%;
}

.innerCircle {
  transform: scale(1.3);
  z-index: 2;

  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: wheel 10s linear infinite;
}

@keyframes wheel {
  from {
    transform: scale(1.3) rotateZ(0);
  }

  to {
    transform: scale(1.3) rotateZ(360deg);
  }
}

.planetCircle {
  position: relative;
  z-index: 2;
  transform-origin: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 1px rgba(43, 43, 43, 0.3);
  animation: planets 10s linear infinite;
}

@keyframes planets {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(-360deg);
  }
}

.innerCircle .planetCircle:nth-child(1) {
  margin-left: -23px;
}

.innerCircle .planetCircle:nth-child(2) {
  margin-right: -23px;
}

.outerCircle {
  transform: scale(1.8);
  z-index: 1;

  border-width: 1px;
  animation: wheel2 20s linear infinite;
}

@keyframes wheel2 {
  from {
    transform: scale(1.8) rotateZ(0);
  }

  to {
    transform: scale(1.8) rotateZ(360deg);
  }
}

.outerCircle .planetCircle {
  position: absolute;
  width: 33px;
  height: 33px;
  animation-duration: 20s;
}

.outerCircle .planetCircle:nth-child(1) {
  top: 50%;
  left: 0;
  margin-left: -16.5px;
}

.outerCircle .planetCircle:nth-child(2) {
  top: 0;
  left: 25%;
  margin-left: 16.5px;
}

.outerCircle .planetCircle:nth-child(3) {
  top: 0;
  right: 25%;
  margin-right: 16.5px;
}

.outerCircle .planetCircle:nth-child(4) {
  top: 50%;
  right: 0;
  margin-right: -16.5px;
}

.outerCircle .planetCircle:nth-child(5) {
  bottom: 0;
  right: 25%;
  margin-right: 16.5px;
}

.outerCircle .planetCircle:nth-child(6) {
  bottom: 0;
  left: 25%;
  margin-left: 16.5px;
}

.sponsors-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sp-title {
  display: flex;
  align-items: center;
  column-gap: 1.75rem;
  margin-bottom: 4rem;
}

.sp-title span {
  font-size: 3rem;
  color: #fefefe;
  text-shadow: 2px 2px 2px #ea4aaa;
  cursor: default;
}

.sp-title img {
  width: 3rem;
  filter: drop-shadow(2px 1px #fefefe);
}

.sponsor-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}

.sponsor-card {
  max-width: 80vw;

  display: grid;
  grid-template-columns: 8rem auto;
  column-gap: 2rem;

  padding: 2rem;
  color: #efefef;
  border: solid 1px rgba(254, 254, 254, 0.5);
  border-radius: 0.5rem;
}

.sponsor-card img {
  width: 100%;
  height: 100%;
  border: solid 2px #eee;
  border-radius: 0.5rem;
}

.sponsor-info {
  display: grid;
  grid-template-rows: 3rem auto 2.75rem;
}

.sponsor-info h1{
  text-shadow: 2px 1px 1px #ea1093;
}

.sponsor-info h4{
  font-weight: 500;
}

.sp-links {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1rem;
}

.sp-links svg{
  filter: drop-shadow(2px 2px 0px #003bb8);
}

.sp-links a {
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.sp-links a:hover {
  text-decoration: underline;
}

.sp-btns{
  display: flex;
  align-items: center;
  column-gap: 1.5rem;

  color: #eee;
  margin: 4rem 0;
}

.donate-btn {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  color: #fefefe;
  font-size: 1.25rem;
  cursor: pointer;

  padding: 0.75rem 1rem;
  border: 2px solid #eee;
  border-radius: 0.325rem;

  transition: all 120ms ease-in-out;
}

.donate-btn:hover{
  background: #fefefe;
  color: #1e2027;
  box-shadow: 2px 2px 4px rgba(36, 35, 50, 0.25);
}

.gh-btn svg:nth-child(3){
  display: none;
}

.gh-btn:hover svg:nth-child(3){
  display: block;
}

.gh-btn:hover svg:nth-child(2){
  display: none;
}

@media only screen and (max-width: 900px) {
  .home {
    height: auto;
    overflow: hidden;
  }

  .intro {
    flex-direction: column-reverse;
    justify-content: unset;
    padding-top: 0;
    align-items: center;
    vertical-align: middle;
  }

  .profileImage {
    transform-origin: center;
    height: 650px;
    --winWidth: 0.55;
    transform: scale(var(--winWidth));
    right: 0;
    place-items: baseline center;
  }

  .introCards {
    /* display: none; */
    width: 98%;
    min-height: 400px;
    margin: 0;
    transform-origin: left center;
    transform: scale(0.65);
    align-self: flex-end;
  }

  .procode {
    font-size: 17px;
  }

  .sp-title{
    column-gap: 1rem;
  }

  .sp-title span{
    font-size: 2rem;
  }

  .sp-title img{
    width: 2.5rem;
  }

  .sponsor-card{
    padding: 1.25rem;
    grid-template-columns: auto;
    row-gap: 1rem;
  }

  .sponsor-info{
    /* font-size: 0.8rem; */
    padding: 0 0.5rem;
    grid-template-rows: auto;
  }

  .sp-links{
    margin-top: 1rem;
    grid-template-columns: auto;
  }

  .sp-links a{
    display: grid;
    grid-template-columns: 1.5rem auto;
  }

  .sp-links svg{
    justify-self: center;
  }

  .sp-btns{
    display: grid;
    grid-template-columns: auto;
    /* flex-direction: column; */
    row-gap: 1rem;
  }

  .sp-divider{
    text-align: center;
  }

  .donate-btn{
    justify-content: center;
    /* width: 12.5rem; */
  }
}
